@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.gallery {
  background-color: #7d94b5;
  padding: 3rem 0;
}

.card-wrap
{
  width:100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items:space-around;
  padding: 3rem 0;

}

.tile
{
  width:380px;
  height:380px;
  margin:10px;
  /* background-color:#444549; */
  display:inline-block;
  background-size:cover;
  position:relative;
  transition: all 0.4s ease-out;
  /* box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.44); */
  overflow:hidden;
  color:white;
  font-family:'Roboto';
  border-radius: 10px;
  box-shadow: 10px 5px 5px rgba(7, 7, 7, 0.425);
}

.tile img
{
  height:100%;
  width:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
  transition: all 0.4s ease-out;
  border-radius: 10px;
  box-shadow: 10px 5px 5px rgba(27, 27, 27, 0.774);
}

.tile .text
{
/*   z-index:99; */
  position:absolute;
  padding:30px;
  height:calc(100% - 60px);
}
.tile h1
{
  color: white;
  margin:0;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  text-align: center;
  margin: 0 0 2rem 0;
}
.tile h2


.tile p
{
  color: white;
  margin:20px 0 0 0;
  line-height: 25px;
  opacity:0;
  transform: translateX(-200px);
  transition-delay: 0.2s;
}
.animate-text
{
  opacity:0;
  transition: all 0.6s ease-in-out;
}

.tile:hover {
/*   background-color:#99aeff; */
box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.64);
  transform:scale(1.05);
} 

.tile:hover img {
  opacity: 0.2;
} 

.tile:hover .animate-text {
  transform:translateX(0);
  opacity:1;
  text-align: center;
  margin: 0 0 2rem 0;
}


.tile:hover span
{
  opacity:1;
  transform:translateY(0px);
}


.cards-header {
    width: auto;
    text-align: justify;
    margin:  auto;
    padding: 1rem;

}

.cards-header h1 {
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem auto;
  font-family: 'Montserrat', sans-serif;
  color:rgb(241, 239, 239);

}

.cards-header p {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color:rgb(245, 239, 239);

}

  @media (max-width: 940px) {
    .card-wrap {
      width: 100%;
    }
  
    .tile { 
      width: 100%;
      height:380px;
    }
  
  }
