@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.NorthCitrus-section-header {
    width: auto;
    padding: 1rem;
    text-align: center;
}

.NorthCitrus-section-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding: 3rem 0 0 0;
}

.NorthCitrus-section-header h2 {
  text-align: center;
  padding: 3rem 0 0 0;
  font-family: 'Dancing Script', cursive;
  font-size: 3rem;
}


.NorthCitrus-section-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.homes {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap ;
}

.homes .thumbnail {
  width: 350px;
  transition: all 1s ease;
}

.homes .thumbnail img {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.homes .thumbnail:hover {
  width: 400px;
}
