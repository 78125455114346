@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.detox {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #11111175;
}

.detox:before {
  content: '';
  position: absolute;
  background: url('../../assets/method-bg-min.webp') no-repeat center top/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.detox .detox-content {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    position: absolute;
    bottom: 20%;
    left: 5%;
    padding: 1rem;
}



.detox-content h1 {
  text-align: center;
  color: rgb(226, 226, 226);
  font-size: 3rem;
  font-family: 'Roboto', sans-serif;
}


/* MEDIA QUERIES - HERO */

@media screen and (max-width:940px) {

.detox-content h1 {
  text-align: center;
  font-size: 2rem;
}

.detox .detox-content {
  width: 100%;
  left: 0%;
}


}