@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.footer {
    width: 100%;
    height: auto;
    position: relative;
    background: #7d94b5;
    margin-top: 2rem;  
}



.footer .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0 0 0;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
}

.footer .top .footer-icon {
    font-size: 3rem;
    color: white;
    cursor: pointer;
}

.footer h1 {
    font-size: 2rem;
    color: white;
}

.footer h2 {
    font-size: 2rem;
}

.footer h3 {
    color: var(--primary-color);
    font-size: 1rem;
}

.footer .col-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
}

.footer h3 {
    margin: 1rem 0;
    text-transform: uppercase;
    color: rgb(250, 162, 0);
    text-align: center;

}


.footer p {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 500;
    text-align: center;
}

.footer form {
    width: 100%;
    margin-bottom: .6rem;
    position: relative;
}

.footer form input {
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #333;
}

.footer form input:focus {
    color: #333;
    outline: var(--primary-color);
    box-shadow: 0 0 8px 0 var(--primary-dark);
}

.footer form .social-icon {
    margin: 1rem 1rem 1rem 0;
    padding: .6rem;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: #f8f8f8;
    font-size: 3rem;
}


.copyright p {
    color: white;
    padding: 2rem 0;
}

@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }



    
}


