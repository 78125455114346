
.about-content {
    padding: 3rem 0;
}

.about-flex {
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.about-left {
    width: 50%;
    margin: auto;
    padding: 1rem;
    text-align: left;
}

.about-left h1 {
    font-size: 1rem;
    color: var(--primary-color);
    padding: 1rem 0;
    letter-spacing: 5px;
}

.about-left p {
    font-size: 16px;
}

.about-left span {
    font-size: 1.2rem;
}


.about-right {
    width: 500px;
    padding: 1rem;
}

.about-right img {
    width: 100%;
    border-radius: 1rem;
}

.about-left span {
    color: var(--secondary-color);
}


@media screen and (max-width: 1200px) {
    .about-left {
        width: 100%;
    }

    .about-left h1 {
        text-align: center;
    }

    .about-left p {
        text-align: left;
    }

    .about-right {
        width: 80%;
    }
}


/* .about-section {
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0);
    padding: 3rem 0;
}

.about-section .content {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.about-section .content p {
    margin: 1rem 0;
}

.about-section p {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}


.about-section .about-header {
    padding: 1rem 0;
    font-size: 2rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
}



.flex-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
    gap: 1rem;
}

.thumbnail img {
    margin: 5px;
    padding: 5px;
    box-shadow: 10px 5px 5px rgb(129, 129, 129);
    border-radius: 1rem;
}

.thumbnail p {
    width: 320px;
    text-align: center;
}

.mission-section button {
    margin: auto;
}

@media screen and (max-width: 940px) {
    .about-section .content {
        width: 100%;
    }

    .about-section h1 {
        font-size: 25px;
    }

    .about-section p {
        font-size: 15px;
    }

    .thumbnail img {
        width: 250px;
        height: 150px;
    }
}





 */
