

@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');

.hero {
    width: 100%;
    height: auto;
    background-color: rgb(0, 0, 0);
    position: relative;
}


#content {
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: relative;
    background-color: rgba(0, 0, 0, 0.473);
  }

.hero-text {
  width: 100%;
  position: absolute;
  bottom: 10%;
  text-align: center;
}

  .hero-text h1 {
    color: white;
    font-size: 6rem;
    letter-spacing: 2px;
    font-family: 'Alice', serif;   
    font-weight: normal; 
    text-transform: uppercase;
  }

  .hero-text h2 {
    color: white;
    letter-spacing: 5px;
    font-weight: normal;
  }

  .hero-text p {
    color: white;
    font-size: 18px;
    padding: .5rem 0;
  }

  .slideshow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  

  .slideshow ul li img{
    height: auto;
  }

  .slideshow li {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 0;
    animation: imageAnimation 35s linear infinite;
  }
  
  .slideshow li:nth-child(1) {
    background: url('../../assets/beverly_rehab_hero_image_1.webp') no-repeat center top/cover;
  }
  
  .slideshow li:nth-child(2) {
    background: url('../../assets/beverly_rehab_hero_image_2.webp') no-repeat center top/cover;
    animation-delay: 7s;
  }
  
  .slideshow li:nth-child(3) {
    background: url('../../assets/beverly_rehab_hero_image_3.webp') no-repeat center top/cover;
    animation-delay: 14s;
  }
  
  .slideshow li:nth-child(4) {
    background: url('../../assets/beverly_rehab_hero_image_4.webp') no-repeat center top/cover;
    animation-delay: 21s;
  }
  
  .slideshow li:nth-child(5) {
    background: url('../../assets/beverly_rehab_hero_image_5.webp') no-repeat center top/cover;
    animation-delay: 28s;
  }
  
  @keyframes imageAnimation {
    0% {
      opacity: 0;
      animation-timing-function: ease-in;
    }
    10% {
      opacity: 1;
      animation-timing-function: ease-out;
    }
    20% {
      opacity: 1
    }
    30% {
      opacity: 0
    }
  }
  
  /* Older browser support */
  .no-cssanimations .slideshow li { opacity: 1; }
  

  .slideshow-caption {
    position: absolute;
    top: 22%;
    width: 100%;
    text-align: center;
    padding: 1rem;
  }

  .slideshow-caption h1 {
    color: white;
    font-size: 8rem;
  }

  .slideshow-caption h2 {
    color: white;
    font-size: 2rem;
    font-family: 'Gothic A1', sans-serif;
  }

  .slideshow-caption h3 {
    color: #e0a708;
    font-size: 1rem;
    padding: 1rem 0;
    letter-spacing: 3px;
  }



  #content .hero-btn button {
    border-radius: 5px;
    padding: 1rem 3rem;
    margin: 1rem 0;
  }

  
  @media screen and (max-width: 940px) {


    .hero-text {
      left: 0;
      text-align: center;
      padding: 1rem;
      width: 100%;
    }

    .hero-text h1 {
      font-size: 4rem;
      letter-spacing: 10px;
      line-height: 4rem;
      }
  
    .hero-text h2 {
      font-size: 1.2rem;
      letter-spacing: 3px;
    }
  
    .hero-text p {
      color: white;
      font-size: 16px;
      padding: .5rem 0;
    }

    
  }