@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.treatment-section-overview {
    padding: 3rem;
}

.treatment-section-overview p {
    padding: 1rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: rgb(65, 66, 66);
}

.treatment-section-overview h1 {
    padding: 1rem 1rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    color: rgb(65, 66, 66);
}

.treatment-plan {
    background: url('../../assets/hero-bg-1.webp') no-repeat center center/cover;
    padding: 3rem;
}


/* SECTION 2.1 */

.treatment-section-tile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.section3 {
    padding: 3rem;
}

.tile-header h1{
    padding: 1rem 1rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    color: rgb(65, 66, 66);
}

.treatment-plan .tile-header p {
    padding: 1rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: rgb(65, 66, 66);}

.treatment-section-tile .tile-image {
    width: 50%;
    padding: 1rem;
}

.treatment-section-tile .tile-image img {
    width: 100%;
    border-radius: 2rem;
    padding: 1rem;
}

.treatment-section-tile .tile-description {
    width: 50%;
    margin: auto;
    padding: 1rem;
}

.treatment-section-tile .tile-description p {
    width: 100%;
    text-align: justify;
    padding: 1rem;
    font-family: 'Poppins', sans-serif;
}

.treatment-section-tile .tile-description h1 {
    font-family: 'Poppins', sans-serif;
    color: black;
}

.wrap-reverse {
    flex-wrap: wrap-reverse;
}

.treatment-section-activities  {
display: flex;
flex-wrap: wrap;
justify-content: center;
padding: 3rem 0;
gap: 2rem;
background: url('../../assets/hero-bg-1.webp') no-repeat center center/cover;
}

.treatment-section-activities .treatment-left {
    width: 600px;
}

.treatment-section-activities .treatment-right {
    width: 600px;
}

.treatment-section-activities .treatment-right img{
    width: 100%;
}

.treatment-section-activities p {
padding: 1rem;
text-align: justify;
}

.treatment-section-activities h1 {
    text-align: center;
}


.treatment-section {
    padding: 1rem;
}

.treatment-section h1 {
    color: rgb(65, 66, 66);
    padding: 2rem 0;
}

.treatment-section p {
    font-size: 16px;
    text-align: left;

}  


@media  screen and (max-width:940px) {
    .treatment-section-tile .tile-description {
        width: 100%;
        padding: 1rem 0;

    }
    
    .treatment-section-tile .tile-image {
        width: 100%;
        padding: 1rem 0;
    }

}