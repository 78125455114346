@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.SoberLiving {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(17, 17, 17, 0.438);
  }
  
  .SoberLiving:before {
    content: '';
    position: absolute;
    background: url('../../assets//menu-bg-sober-living.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


  .SoberLiving .SoberLiving-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}


.SoberLiving h1 {
    color: white;
    font-size: 4rem;
}



/*  SECTION 1 */

  .SoberLiving-wrap {
    padding: 3rem 0;
  }


.SoberLiving-wrap .SoberLiving-title h1 {
    font-size: 1.5rem;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
    font-weight: 400;
}


.SoberLiving-box {
    width: 500px;
}

.SoberLiving-box img {
     height: auto;
     width: 100%;
     border-radius: 20px;
     padding: 10px;
}


/* MEDIA QUERIES - HERO */

@media screen and (max-width:940px) {

.SoberLiving h1 {
    font-size: 2rem;
    text-align: center;
}


}

